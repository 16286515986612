.flags-sprite,
.flags-american,
.flags-china,
.flags-france,
.flags-germany,
.flags-italy,
.flags-japan,
.flags-korea,
.flags-portugal,
.flags-russia,
.flags-spain{background-image:url('ui/images/flags.png');background-repeat:no-repeat}
.flags-american{background-position:0 0;height:32px;width:32px}
.flags-china{background-position:0 -32px;height:32px;width:32px}
.flags-france{background-position:0 -64px;height:32px;width:32px}
.flags-germany{background-position:0 -96px;height:32px;width:32px}
.flags-italy{background-position:0 -128px;height:32px;width:32px}
.flags-japan{background-position:0 -160px;height:32px;width:32px}
.flags-korea{background-position:0 -192px;height:32px;width:32px}
.flags-portugal{background-position:0 -224px;height:32px;width:32px}
.flags-russia{background-position:0 -256px;height:32px;width:32px}
.flags-spain{background-position:0 -288px;height:32px;width:32px}