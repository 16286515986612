//////////////////////////////////
//////////////////////////////////
//Flex Elements
//////////////////////////////////
//////////////////////////////////
.center-elements-m{
	display:flex;
	align-items:center;
} //Center Childrens In Elements

.bold{
	font-weight:bold;
}


.space-between-m{
	display:flex;
	justify-content:space-between;
}// Give space between elemetns

.space-around-m{
	display:flex;
	justify-content:space-around;
}// Give space between elemetns

//////////////////////////////////
//////////////////////////////////
//Tex Styling
//////////////////////////////////
//////////////////////////////////
.center-text-m{
	display:flex;
	align-items:center;
	justify-content:center;
	text-align:center;
}// Center Text In a div

.hover-button-m{
	transition: 1s;
	&:hover{
		bottom:5px;
	}
}

.overflow-auto{
	overflow:auto;
}

.vertical-middle-center{
    vertical-align: middle !important;
}

//////////////////////////////////
//////////////////////////////////
//Table fixed css
//////////////////////////////////
//////////////////////////////////
.line-height-25{
	line-height:25px !important;
}

//////////////////////////////////
//////////////////////////////////
//Magin & Padding
//////////////////////////////////
//////////////////////////////////
.maring-auto-width-300{
	max-width:300px;
	margin:0 auto;
}


.margin-top-30{
	margin-top:30px !important;
}

.margin-top-20{
	margin-top:30px ;
}


.margin-top-15{
	margin-top:15px;
}

.padding-25-5{
	padding:25px 5px;
}

.padding-bottom-25{
	padding-bottom:25px !important;
}


.padding-no{
	padding:0px;
}

.custom-logo-style-m{
	font-family: Roboto,"Helvetica Neue",sans-serif;
	color:$main-color;
	text-align:center;
	padding: 25px 0px;
    font-size: 30px;
}

.custom-logo-green-m{
	width: 80px;
    margin: 0 auto;
    display: block;
}

.text-align-center{
	text-align:center !important;
}

.text-align-padding-10{
	text-align:center;
	padding:5px;
}
//////////////////////////////////
//////////////////////////////////
//Height & Width
//////////////////////////////////
//////////////////////////////////
.min-height-56{
	min-height:56px;
}

.max-height-500{
	max-height:500px;
}

.td-width-35{
	width:35%;
}

.custom-tabs-height{
	max-height:600px;
	min-height:500px;
	overflow-y:auto;
}

.custom-tabs-height-sm{
	max-height:600px;
	min-height:200px;
	overflow-y:auto;
}

.custom-tabs-height-md{
	max-height:600px;
	min-height:270px;
	overflow-y:auto;
}
//////////////////////////////////
//////////////////////////////////
//Responsive Buttons
//////////////////////////////////
//////////////////////////////////
.call-me-a-responsive-button{
	padding:0 15px;
    position: fixed;
    bottom: 25px;
    z-index: 2;
}

@media only screen and (max-width: 765px) {
	.call-me-a-responsive-button{
	    position: fixed;
	    top: 74px;
	    left: -5px;
	    bottom:initial;
	    z-index: 9999;


	    // span{
	    // 	display:none;
	    // }

	    // button{
	    // 	min-width:0px;
	    // 	width:60px;
	    // 	height:60px;
	    // 	border-radius: 50%;
	    // }
	}

}
//////////////////////////////////
//////////////////////////////////
//Clear
//////////////////////////////////
//////////////////////////////////

.clear-both{
	clear:both;
}
//////////////////////////////////
//////////////////////////////////
//Error
//////////////////////////////////
//////////////////////////////////

.invalid-color{
	color:rgb(213,0,0);
	font-size:12px;
	line-height:14px;
	padding-top:5px;
}

.invalid-border-color{
	border-color:rgb(213,0,0);
}

.invalid-border-color-submitted{
	border-color:rgb(213,0,0);
}
//////////////////////////////////
//////////////////////////////////
//Input
//////////////////////////////////
//////////////////////////////////
.fix-input-position{
	margin-bottom: 5px;
    padding-bottom: 25px;
    margin-top: 15px;
}
//////////////////////////////////
//////////////////////////////////
//confirmation-dialog
//////////////////////////////////
//////////////////////////////////

.confirmation-dialog{
    min-width: 200px;
    max-width: 350px;
    min-height: 250px;
    max-height: 300px;
    width: 50vw;
    height: 50vh;
}

.dialog-header{
	font-size: 25px;
    text-align: center;
    padding: 25px;
}
/* custom inclusion of right, left and below tabs */
// = Tabs
.tab-content {
  min-height: 275px;
  margin-bottom: 20px;
}

// = Side Tabs
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
  padding-top:15px;
  overflow-x: auto;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
  padding-top: 15px;
  min-height: 275px;
  @media only screen and (max-width: 765px) {
  float: none;
  margin-right: 0px;
  border-right: none;
  text-align: center;
  min-height: 0px;
  }

}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}


.btn-product {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
    cursor: initial;
}

.price {
    padding-top: 10px;
    font-size: 24px;
}

.card-product {
	font-weight: 500;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7) !important;
}

.product-img-container {
    position: relative;
    /* Remove the parts of the circle that is outside of the image */
    overflow:hidden;
}
.product-img-container:after {
    content: '';
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    /* Add more width */
    width: 120%;
    /* To form a square, the padding-bottom, needs to have the same value as the width property */
    padding-bottom: 120%;
    box-shadow: inset 0px 0px 150px 60px rgba(0,0,0,0.4);
    border-radius: 50%;
}

.cart-button {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    font-weight: normal;
    text-align: right;
    padding-left: 10px;
}

.modal-backdrop {
    z-index: 1050;
}

.modal {
    z-index: 1060;
}

.modal-dialog {
    overflow-y: initial !important
}

.modal-body {
    height: 500px;
    overflow-y: auto;
}
