//Giftcard - Issue
.issue-giftcard-amount-options{

    input[type=radio]{
	    opacity: 0; 
	    position: absolute;
    }

    .radio{
        cursor:pointer;
        background-color:#E6E9ED;
        text-align: center;
        height: 60px;
        display:flex;
        justify-content: space-around;
        align-items:center;
        font-weight: bold;
        font-size:1.5em;
        border:1px solid $hover-font;
        padding-top: 0;
        &:hover{
		    background-color:$main-color !important;
		    color:white;       	
        }
    }
}

.amount-checked{
    background-color:$main-color !important;
    color:white;
}

.custom-amount{
	min-height:50px;
}

.addRecipient{
	height: 50px;
	background:$main-color;
	color:$hover-font;	
}

/*
md-input-container{
	&:nth-of-type(n){
		margin: 5px 0px 3px 0px;
		padding:0;
	}
}
*/

//Add Card Button
.addCard{
	height:75px;
	background:$main-color;
	color:$hover-font;
}


.sub-section-header-giftcard{
	text-align:center;
	color:$hover-font;
	background:$main-color;
	border-top:1px solid $sub-color;
	border-bottom:1px solid $sub-color;
}

.sub-section-header-recipent{
	text-align:center;
	color:$main-color;
}

//Search Gift Card

//Scanner Section
// #qrcode-link{
// 	text-align:center;
// 	box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
// 	color: rgb(255,255,255);
//     background-color: rgb(0,150,136);
//     padding: 10px 16px;
//     font-size: 18px;
//     line-height: 1.33;
//     border-radius: 2px;
// }

.scanner-screen {
	padding-top:30px;
	width:100%;
	margin:0 auto;
}

.no-padding {
	padding:0px;
}

.new-search {
	position:fixed; 
	bottom:25px;
	z-index:2;
}

.special-block {
	float: left;
	margin-right: 5px; 
	margin-bottom: 5px; 
}
.special-selector {
	input{
	    margin:0;padding:0;
	    -webkit-appearance:none;
	       -moz-appearance:none;
	            appearance:none;
	}

	input:active +.special-img {
		opacity: .9;
	}

	input:checked +.special-img {
	    -webkit-filter: none;
	       -moz-filter: none;
	            filter: none;
	}

	input:checked +.special-img +.special-selected {
	    display: block;
	}
}
.special-img {
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    background-position: center;
    display:inline-block;
    border: 1px solid #AAAAAA;
    width:150px;height:100px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.2) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.2) grayscale(1) opacity(.7);
            filter: brightness(1.2) grayscale(1) opacity(.7);
}
.special-img:hover {
    -webkit-filter: brightness(1) grayscale(.7) opacity(.9);
       -moz-filter: brightness(1) grayscale(.7) opacity(.9);
            filter: brightness(1) grayscale(.7) opacity(.9);
}
.special-label {
	font-size: 12px;
    padding-left: 5px;
}
.special-choice {
	margin-top: 8px;
	clear: both;
	text-align: center;
	font-weight: bold;
	background-color: #009688;
	color: #FFFFFF;
	font-size: 21px;
	padding: 10px;
}
.special-selected {
	display: none;
	position: relative;
    font-size: 30px;
    z-index: 1000;
    color: #009688;
    float: right;
    margin-right: -25px;
    left: -30px;
    top: -112px;
}

.discount {
	padding-top: 5px;

	input {
		display: inline-flex;
		color: #000 !important;
		margin-left: 1px;
		margin-right: 1px;
	}
	
	input.tiny {
		width: 40px;
	}

	input.reg {
		width: 150px;
	}

	.note {
		color: #888;
		font-size: 13px;
		margin-top: 10px;
	}

	.input-group {
		width: 80px;
		display: inline-table;
	}

	.ig-offset {
		position: relative;
	    display: inline;
	    vertical-align: 13px;
	    padding-left: 5px;
	}
}

.field-addon {
	display: inline;
    float: left;
    position: absolute;
    margin-top: 6px;
}

.with-addon {
	padding-left: 15px !important;
}

.promo-amount {
	font-size: 20px;
}